import { EuiIcon, EuiSideNav } from '@elastic/eui';
import React, { useContext } from 'react'
import { ViewContext } from '../../Context';


const Drawer = ({isAdmin, isVisible}) => { 
            
  const { changeView } = useContext(ViewContext)
  
  if (!isVisible){
      return <EuiSideNav />
  }
    
    const topLinks = [
        {
          id: "AccountTab",
          name: 'My Account',
          icon: <EuiIcon type='user'/>,
          items: [
              {
                name: 'Account Settings',
                iconType:  <EuiIcon type='dashboardApp'/>,
                onClick: () => changeView({
                  viewName: "ACCOUNT_SETTINGS"
                })
              },
            ],
        },
        {
          id: "HelpTab",
          name: 'Help',
          icon: <EuiIcon type='help'/>,
          onClick: () => window.open("https://docs.tutela.cybersift.io", '_blank')
        },
      ];
    
      const appAdminLinks = [
        {
          id: "AdminDash",
          name: 'Administrator Dashboard',
          icon: <EuiIcon type='users'/>,
          onClick: () => changeView({
            viewName: "ADMIN_DASH"
          })
        },        
      ];

      const forensicsLinks = [
        {
          id: "forensicsTab",
          name: 'Forensics',
          icon: <EuiIcon type="./img/fingerprint.svg" size="m" title="Forensics" />,
          items: [
            {
              id: "Xev",
              name: 'eXtended Endpoint Visibility',
              icon: <EuiIcon type='inputOutput'/>,
              onClick: () => changeView({
                viewName: "XEV"
              })
            },
            {
              id: "OsqueryEditor",
              name: 'Host Query Editor',
              icon: <EuiIcon type='eql'/>,
              onClick: () => changeView({
                viewName: "OSQUERY_EDITOR"
              })
            },
            {
              id: "HostForensicsDiscover",
              name: 'Host Forensics Dashboard',                
              icon: <EuiIcon type='discoverApp'/>,
              onClick: ()=> changeView({
                viewName: "HOST_FORENSICS_DASH"
              })
            },
            {
              id: "HOST_FORENSICS_IDS_DASH",
              name: 'Host Forensics IDS',                
              icon: <EuiIcon type="./img/radarIDS.svg" size="m"/>,
              onClick: ()=> changeView({
                viewName: "HOST_FORENSICS_IDS_DASH"
              })
            },            
          ]
        }
      ];
  
      const portaLinks = [
        {
          id: "portaTab",
          name: 'Porta',
          icon: <EuiIcon type="./img/upload-cloud-white.svg" size="m" title="Porta" />,
          items: [
            {
              id: "PortaProfiles",
              name: 'Porta Profiles',
              icon: <EuiIcon type='inputOutput'/>,
              onClick: () => changeView({
                viewName: "PORTA_PROFILES"
              })
            },
            {
              id: "PortaRegexes",
              name: 'Porta Regexes',
              icon: <EuiIcon type='eql'/>,
              onClick: () => changeView({
                viewName: "PORTA_REGEXES"
              })
            }
          ]
        },
      ];
    
      const adminLinks = [
        {
          id: "AdminTab",
          name: 'Admin',
          icon: <EuiIcon type='managementApp'/>,
          items: [
              {
                id: "HostGroupTab",
                name: 'Host groups',
                icon: <EuiIcon type='tag'/>,
                onClick: () => changeView({
                  viewName: "HOST_GROUPS"
                })
              },
              {
                id: "AgentsTab",
                name: 'Agent Management',
                icon: <EuiIcon type='devToolsApp'/>,
                onClick: () => changeView({
                  viewName: "AGENTS"
                })
              },
              {
                id: "DomainsTab",
                name: 'Online Presence Monitoring Management',
                icon: <EuiIcon type='watchesApp'/>,
                onClick: () => changeView({
                  viewName: "DOMAINS"
                })
              },                          
              {
                id: "SetupWizardTab",
                name: 'Setup Wizard',
                icon: <EuiIcon type='uptimeApp'/>,
                onClick: () => changeView({
                  viewName: "SETUP_WIZARD"
                })
              }
            ],
        },
      ];
    

    const analyzeLinks = [
        {
          id: "AnalyzeLinks",
          name: 'Analyze',
          icon: <EuiIcon type='logoBusinessAnalytics'/>,
          items: [
              {
                id: "DiscoverDashboard",
                name: 'Discover',                
                icon: <EuiIcon type='discoverApp'/>,
                onClick: ()=> changeView({
                  viewName: "DISCOVER"
                })
              },   
              
              {
                id: "WebScannerDashboard",
                name: 'Web Scanner',
                icon: <EuiIcon type='inspect'/>,
                onClick: () => changeView({
                  viewName: "WEB-SCANNER-DASHBOARD"
                })
              },              
              
              {
                id: "ReportingTab",
                name: 'Reporting',
                icon: <EuiIcon type='reportingApp'/>,
                onClick: ()=> changeView({
                  viewName: "PDF_REPORT"
                })
              },   
              {
                id: "PersonalThreatFeed",
                name: 'Threat Feed',
                icon: <EuiIcon type='download'/>,
                onClick: () => changeView({
                  viewName: "THREAT_FEED"
                })
              },
            ],
          },
      ];
    
      const securityLinks = [
        {
          id: "AssetCategory",
          name: 'Scans and Assets',
          icon: <EuiIcon type='logoSecurity'/>,
          items: [
              {
                name: 'Scans',
                icon: <EuiIcon type='securityApp'/>,
                onClick: ()=> changeView({
                  viewName: "SCANS"
                })
              },
              {
                name: 'Assets and Services',
                icon: <EuiIcon type='securityAnalyticsApp'/>,
                onClick: ()=> changeView({
                  viewName: "ASSETS"
                })
              },
              // {
              //   id: "Flashlight",
              //   name: 'Flashlight',
              //   icon: <EuiIcon type='inputOutput'/>,
              //   onClick: () => changeView({
              //     viewName: "FLASHLIGHT"
              //   })
              // },
            ],
        },
      ];
  
      const onlinePresenceMonitoringLinks = [
        {
          id: "OnlinePresenceMonitoringCategory",
          name: 'Online Presence Monitoring',
          icon: <EuiIcon type='watchesApp'/>,
          items: [
              {
                id: "DomainPhishMonitor",
                name: 'Data Leak & Phishing Detection',                
                icon: <EuiIcon type='classificationJob'/>,
                onClick: ()=> changeView({
                  viewName: "DOMAIN_PHISH_MONITOR"
                })
              },
              {
                id: "UrlScan",
                name: 'URL Scan',                
                icon: <EuiIcon type='branch'/>,
                onClick: ()=> changeView({
                  viewName: "URL_SCAN"
                })
              },
              {
                id: "SubDomainsTab",
                name: 'External Domain Management',
                icon: <EuiIcon type='globe'/>,
                onClick: () => changeView({
                  viewName: "SUBDOMAINS"
                })
              },
            ],
        },
      ];

      const pmacLinks = [
        {
          id: "PmacCategory",
          name: 'Patch Management & Application Control',
          icon: <EuiIcon type='upgradeAssistantApp'/>,
          items: [
              {
                id: "PatchManagement",
                name: 'Patch Management',                
                icon: <EuiIcon type='uptimeApp'/>,
                onClick: ()=> changeView({
                  viewName: "PATCH_MANAGEMENT"
                })
              }
            ],
        },
      ]

  const userItems = [...topLinks, ...analyzeLinks, ...securityLinks, ...pmacLinks, ...onlinePresenceMonitoringLinks, ...portaLinks, ...adminLinks] // ...forensicsLinks

  const navItems = () => isAdmin? [...userItems, ...appAdminLinks] : userItems


  //   return <EuiNavDrawer ref={navDrawerRef}>
  //   <EuiNavDrawerGroup listItems={topLinks} />
  //   <EuiHorizontalRule margin="none" />
  //   <EuiNavDrawerGroup listItems={analyzeLinks} />
  //   <EuiNavDrawerGroup listItems={securityLinks} />
  //   <EuiHorizontalRule margin="none" />
  //   <EuiNavDrawerGroup listItems={adminLinks} />
  //   {isAdmin ? <>
  //     <EuiHorizontalRule margin="none" />
  //     <EuiNavDrawerGroup listItems={appAdminLinks} />   
  //   </> : null}                     
  // </EuiNavDrawer>

  return <EuiSideNav
      items={navItems()}
  />
}

export default Drawer