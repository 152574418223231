export const filterMapper = (filters, sortDirection, sortField, pageSize) => {
    return {
        Scan: filters.scanFilter.toString(),
        Port: filters.portFilter.toString(),
        Host: filters.addressFilter.toString(),
        Severity: filters.severityFilter.toString(),
        TutelaSeverity: filters.tutelaseverityFilter.toString(),
        Status: filters.resolutionFilter.toString(),
        SearchQuery: filters.searchQuery.toString(),
        HostGroup: filters.hostgroupFilter.toString(),
        Exploitable: filters.exploitFilter.toString(),
        DetectionType: filters.detectionType.toString(),
        Tags: filters.tagFilter.toString(),
        Version: filters.versionFilter.toString(),
        Cve: filters.cveFilter.toString(),
        SortDirection: sortDirection,
        SortField: sortField,
        ShowLatest: filters.showLatest,
        StartDateFilter: filters.startDateFilter,
        EndDateFilter: filters.endDateFilter,
        EntityTypeFilter: filters.entitytypeFilter,
        PageSize: pageSize
    }
}

export default filterMapper;